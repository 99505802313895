import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { View, 
    Dimensions,
    Text } from 'react-native'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const windowWidth = Dimensions.get('window').width;

export default function SupportModal(props) {
  const open = props.open
  const setOpen = props.setOpen


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Case Submitted!</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    }}>

                <CheckCircleOutlinedIcon style={{fontSize: windowWidth * 0.15, color: 'green'}}/>
          </Box>
          
        </DialogContent>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>One of the Pattern team will reach out to you.</DialogTitle>
        <DialogActions>
            <Button 
                //onClick={handleClose}
                href={'/home'}
                sx={{fontWeight: 600}}
            >
            Return to Homepage
            </Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}