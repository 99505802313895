import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TableChartIcon from '@mui/icons-material/TableChart';
import SavingsIcon from '@mui/icons-material/Savings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import PivotTableChartTwoToneIcon from '@mui/icons-material/PivotTableChartTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CalculateIcon from '@mui/icons-material/Calculate';
import PlaceIcon from '@mui/icons-material/Place';
import WebIcon from '@mui/icons-material/Web';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


const DrawerIcons = {
    GoogleIcon,
    FacebookIcon,
    TableChartIcon,
    SavingsIcon,
    AnalyticsIcon,
    TableChartTwoToneIcon,
    PivotTableChartTwoToneIcon,
    CalendarMonthTwoToneIcon,
    PictureAsPdfIcon,
    SettingsTwoToneIcon,
    AutoGraphTwoToneIcon,
    CampaignTwoToneIcon,
    DashboardCustomizeIcon,
    CoPresentIcon,
    CalculateIcon,
    PlaceIcon,
    WebIcon,
    QueryStatsIcon,
    GroupIcon,
    AccountTreeIcon,
    SupportAgentIcon
}

export default DrawerIcons