import React, { useState } from 'react';
import { View, 
    StyleSheet, 
    Dimensions,
    Text } from 'react-native'
import SupportForm from './SupportForm';
import SupportModal from './SupportModal';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const SupportPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const userName = configArray.userName
    const userNickname = configArray.userNickname
    const clientID = configArray.clientID
    const [submitted, setSubmitted] = useState(false)

    const titleText = {
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.015,
        color: '#0a2342',
        fontWeight: 600
    }

    const saveHandler = (config) => {
        console.log(config)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'support_submission',
            date: config.date || null,
            client: config.client || null,
            userName: config.userName || null,
            userNickname: config.userNickname || null,
            typeValue: config.typeValue || null,
            reportValue: config.reportValue || null,
            criticalBool: config.criticalBool || null,
            descriptionValue: config.descriptionValue || null,
            status : 'New',
        });
        setSubmitted(true)
    }

    return (
        <View style={styles.container}>
                <View style={styles.titlePanel}>
                    <Text style={titleText}>
                        Open a support case:
                    </Text>
                </View>
                <View style={styles.formPanel}>
                    <SupportForm
                        theme={theme}
                        configArray={configArray}
                        saveHandler={saveHandler}
                    />

                </View>
            <SupportModal
                open={submitted}
                setOpen={setSubmitted}
            />
            
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: (windowHeight * 0.93),
        backgroundColor: 'white'
        //flex: 1,
    },
    titlePanel: {
        width: '80%',
        height: '10%',
        borderWidth: 0,
        borderColor: 'blue',
        justifyContent: 'center',
        alignItems: 'center',

    },
    formPanel: {
        width: '80%',
        height: '90%'
    }
    
})

export default SupportPanel