import React from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import SupportTicketsTable from './SupportTicketsTable';

const windowWidth = Dimensions.get('window').width;

const SupportTicketsPanels = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const supportData = props.supportData

    const awaitingCases = supportData.awaitingCases
    const awaitingCasesCount = supportData.awaitingCasesCount || 0
    const newCases = supportData.newCases
    const newCasesCount = supportData.newCasesCount || 0
    const openCases = supportData.openCases
    const openCasesCount = supportData.openCasesCount || 0
    const resolvedCases = supportData.resolvedCases
    const resolvedCasesCount = supportData.resolvedCasesCount || 0
    const backlogCases = supportData.backlogCases
    const backlogCasesCount = supportData.backlogCasesCount || 0
    const totalCasesCount = supportData.totalCasesCount || 0
    

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <MetricPanel
                    theme={theme}
                    value={totalCasesCount}
                    text={'Total Cases'}
                />
                <MetricPanel
                    theme={theme}
                    value={newCasesCount}
                    text={'New'}
                />
                <MetricPanel
                    theme={theme}
                    value={openCasesCount}
                    text={'Open'}
                />
                <MetricPanel
                    theme={theme}
                    value={awaitingCasesCount}
                    text={'Awaiting Input'}
                />
                <MetricPanel
                    theme={theme}
                    value={resolvedCasesCount}
                    text={'Resolved'}
                />

            </View>
            <View style={styles.body}>
                <SupportTicketsTable
                    theme={theme}
                    awaitingCases={awaitingCases}
                    newCases={newCases}
                    openCases={openCases}
                    resolvedCases={resolvedCases}
                    backlogCases={backlogCases}
                    backlogCasesCount={backlogCasesCount}
                />

            </View>

        </View>
    )

}

const MetricPanel = (props) => {

    const theme = props.theme
    const value = props.value
    const text = props.text

    const container = {
        height: '100%',
        width: '20%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%'
    }

    const title = {
        width: '100%',
        height: '25%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const titleText = {
        fontFamily: 'Electrolize',
        color: theme.color,
        fontSize: windowWidth * 0.015
    }

    const metric = {
        width: '100%',
        height: '75%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const metricText = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.05,
        alignText: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    return (
        <View style={container}>
            <View style={title}>
                <Text style={titleText}>
                    {text}
                </Text>
            </View>
            <View style={metric}>
                <Text style={metricText}>
                    {value}
                </Text>
            </View>

        </View>

    )
}

export default SupportTicketsPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    header: {
        height: '20%',
        width: '100%',
        flexDirection: 'row',
        borderWidth: 0,
        borderColor: 'red',
    },
    body: {
        height: '80%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
        padding: '1%'
    }
})